<template>
    <div class="sports section page">
        <div class="content side">
            <h3> Sports </h3>

            <div class="filter">
                <div class="item" v-for="(s, i) in sports" :key="i" :class="isSportActive(s.name)"
                    @click="changeSportFilter(s.name)" v-ripple>
                    <img :src="s.img" />
                    <span> {{ s.displayName }} </span>
                </div>
            </div>
        </div>

        <div class="content blocks content-row" >
            <div class="sport" v-for="(s, i) in activeLive" :key="i">
                <Sport :sport="s" v-ripple />
            </div>
        </div>

        <div class="content list">
            <div class="sport l" v-for="(s, i) in activeSports" :key="i">
                <Sport :sport="s" mode="list" v-ripple />
            </div>
        </div>

    </div>
</template>

<script>
const Sport = () => import("@/components/Sport")

export default {
    name: 'Sports',

    components: {
        Sport,
    },

    watch: {
    },

    data: function () {
        return {
            sports: [],
            live: [],
            allSports: [],
            activeLive: [],
            activeSports: [],
            activeSportFilter: '',
        }
    },

    methods: {
        async loadSports() {
            let request = await fetch(process.env.VUE_APP_SERVER + '/liveSports')
            let data = await request.json()
            this.live = data.live
            this.activeLive = data.live

            let allRequest = await fetch(process.env.VUE_APP_SERVER + '/allSports')
            let allData = await allRequest.json()
            this.allSports = allData.live
            this.activeSports = data.live
            this.changeSportFilter('')
        },

        isSportActive(s) {
            if (s == this.activeSportFilter) {
                return 'active'
            } else {
                return ''
            }
        },

        changeSportFilter(s) {

            this.activeLive = []
            this.activeSports = []
            this.$nextTick(() => {
                if (s == this.activeSportFilter) {
                    this.activeSportFilter = ''
                    this.activeLive = this.live
                    this.activeSports = this.allSports
                } else {
                    this.activeSportFilter = s
                    this.activeLive = this.live.filter((x) => {
                        if (x.type == s) {
                            return x
                        }
                    })

                    this.activeSports = this.allSports.filter((x) => {
                        if (x.type == s) {
                            return x
                        }
                    })
                }
            })
        },
    },

    computed: {

    },

    mounted: async function () {
        this.$nextTick(() => {
            this.sports = window.sportsData
        })

        this.loadSports()
    }
}

</script>

<style scoped lang="scss">
.content.side>h3 {
    padding-left: 20px;
}

.sports {}

.content.list {
    margin: 5%;

    .sport.l {
        margin-bottom: 15px;
    }

}

.content.side {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
        padding-top: 0
    }
}

.filter {
    display: flex;
    flex-direction: row;
    margin-right: 20px;

    .item {
        width: auto;
        overflow: hidden;
        display: flex;
        margin: 4px;
        align-items: center;

        span {
            display: none;
            font-size: 9px;
            margin-left: 5px;
            margin-right: 5px;
        }

        img {
            width: 20px;
            object-fit: contain;
            border-radius: 50%;
        }

        &.active {
            border: 1px solid white;
            border-radius: 3px;
            background-color: white;

            img {
                position: relative;
                width: 17px;
                top: -0px;
                left: -1px;
            }

            span {
                color: black;
                display: block;
            }
        }

    }
}

.content.blocks {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 20px;
    justify-content: space-between;

    &.content-row{
        padding: 0;
    }

    .sport {
        width: calc(50% - 5px);
        height: 240px;
        margin-bottom: 10px;
    }
}
</style>
